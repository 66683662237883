<template>
  <div class="top-pattern d-flex justify-space-between align-items-center">
    <div class="d-flex align-items-center">
      <div class="d-flex flex-direction-column align-items-center cursor-pointer" style="border-right: 1px solid #d4d9e5;padding-right:10px" @click="returnHome()">
        <img src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/0d4e494e3fdcb424184ec4e70590c0f7.png" alt="" height="30px">
      </div>
      <div>
        <!-- <span class="top-text">申请注册账户</span> -->
      </div>
    </div>
    <div>
      <div class="mar-left-50">
        <el-dropdown @command="handleCommandLanguage">
          <span class="el-dropdown-link">
            {{ $t('language.language') }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh_CN">{{ $t('language.chinese') }}</el-dropdown-item>
            <el-dropdown-item command="en_US">{{ $t('language.english') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  data() {
    return {

    }
  },
  methods: {
    // 返回首页
    returnHome(){
      this.$router.push({path:'/'})
    },
    // 切换语言
    handleCommandLanguage(command) {
      if (command === "zh_CN") {
        Cookies.set('language', 'zh_CN')
        this.$i18n.locale = 'zh_CN'
      } else {
        Cookies.set('language', 'en_US')
        this.$i18n.locale = 'en_US'
      }
      this.$router.go(0);
    },
  }
}
</script>
<style scoped>
.top-text {
  margin-left: 10px;
}
</style>