<template>
  <div>
    <top-Pattern></top-Pattern>
    <router-view style="min-height: 100vh" />
  </div>
</template>
<script>
import topPattern from '@/components/topPattern.vue'
export default {
  components: {
    topPattern,
  }
}
</script>
